<template>
  <div class="home">
    <div class="home__banner" v-if="state.bannerList.length">
      <el-carousel
        height="250px"
        :indicator-position="state.bannerList.length === 1 ? 'none' : ''"
        :arrow="state.bannerList.length === 1 ? 'never' : 'always'"
      >
        <el-carousel-item @click="onBannerClick(item)" v-for="item in state.bannerList" :key="item.id">
          <img :src="$getShowUrl(item.imgId)" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="home__container">
      <!-- 左侧内容模块 -->
      <section class="main-content">
        <!-- 用户信息 -->
        <div class="user-section">
          <!-- 当前用户信息 -->
          <div class="user-info">
            <div class="avatar">
              <userAvatar :url="user.headUrl"></userAvatar>
            </div>
            <div class="user-option flex-align-center">
              <div class="name">
                <wxview type="userName" :openid="user.username">{{ user.username }}</wxview>
                ，{{ timeFix() }}
              </div>
            </div>
          </div>
        </div>
        <!-- 消息列表 -->
        <div class="messages" v-if="bulletinState.list.length">
          <div class="header">
            <div class="tip">
              您有
              <span @click="goBulletin">{{ bulletinState.count }}</span>
              条未读消息
            </div>
          </div>
          <div class="message-list">
            <div
              class="message-item"
              @click="itemClick(message)"
              v-for="message in bulletinState.list"
              :key="message.id"
            >
              <div class="text ellipsis">{{ message.title }}</div>
              <i class="iconfont icon-iconmore"></i>
              <div class="close">
                <i class="iconfont el-icon-close" @click.stop="onItemDelClick(message)"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="shortcut-content" v-loading="appListLoading">
          <div class="sub-title">常用入口</div>
          <div class="menu-wrap">
            <ShortcutItem
              v-if="hasAddressPromise"
              :icon="ICON_ADDRESS_PROMISS"
              name="通讯录"
              @click="addressListClick"
            ></ShortcutItem>

            <template v-for="app in appList" :key="app.id">
              <ShortcutItem :icon="$getShowUrl(app.fileId)" :name="app.name" @click="appClick(app)"></ShortcutItem>
            </template>
            <!-- <div v-if="appList.length === 0" class="app-null">没有可访问的应用</div> -->
          </div>
        </div>
      </section>
      <section class="company">
        <div class="company__logo">
          <img :src="logo" alt="" />
        </div>
        <div class="company__name">
          {{ companyInfo.companyName }}
        </div>
        <div class="company__info" v-if="hasPromise" v-loading="companyState.loadingState">
          <div class="company__info__line"></div>
          <div class="company__info__item">
            <label>部门数</label>
            <i class="dot">:</i>
            <span>{{ companyCount.departCount }}&nbsp;个</span>
          </div>
          <div class="company__info__item">
            <label>小区数</label>
            <i class="dot">:</i>
            <span>{{ companyCount.communityCount }}&nbsp;个</span>
          </div>
          <div class="company__info__item">
            <label>总户数</label>
            <i class="dot">:</i>
            <span>{{ companyCount.houseCount }}&nbsp;户</span>
          </div>
          <div class="company__info__item">
            <label>企业人数</label>
            <i class="dot">:</i>
            <span>{{ companyCount.employeeCount }}&nbsp;人</span>
          </div>
        </div>
      </section>
    </div>
  </div>
  <home-guide></home-guide>
</template>
<script>
import { computed, defineComponent, onMounted, reactive } from 'vue'
import ShortcutItem from './components/shortcut-item'
import { useUser } from '@/hooks'
import { useCommonlyApps } from '@/hooks/apps'
import { timeFix } from '@/common/utils'
import { useLogo, useCompany } from '@/hooks/company'
import store from '@/store'
import { getAdsList } from '@/apis'
import { timeFormat } from '@/common/utils/format'
import { useBulletinList } from '@/hooks/message'
import { useAppClick } from '@/hooks/app-click'
import { useRouter } from 'vue-router'
import HomeGuide from '@/views/guide/home/index.vue'
import { ICON_ADDRESS_PROMISS } from '@/common/const'
import { getIndexData } from '@/apis/sys'

export default defineComponent({
  components: { ShortcutItem, HomeGuide },
  setup() {
    const state = reactive({
      bannerList: [],
      loginInterval: null
    })
    const router = useRouter()
    const { user } = useUser()
    const { appList, loadingState: appListLoading } = useCommonlyApps(true)
    const { state: companyState, companyCount, initCompanyInfo } = useCompany()
    const { state: bulletinState, itemClick, readBulletin } = useBulletinList(true, 4, { read: true })
    const logo = useLogo()

    const companyInfo = computed(() => {
      return store.state.companyInfo
    })
    // 查看统计权限
    const hasPromise = computed(() => {
      return user.value.frontPageState
    })
    // 通讯录权限
    const hasAddressPromise = computed(() => {
      return user.value.newsletterState
    })
    const addressListClick = () => {
      router.push({ name: 'AddressList' })
    }
    const initAdsList = async () => {
      try {
        const endTime = timeFormat(new Date())
        const { data } = await getAdsList(endTime)
        state.bannerList = data || []
      } catch (error) {
        console.log('initAbsList error', error)
      }
    }
    const onBannerClick = banner => {
      const { id: type } = banner.jumpType || {}
      if (!type) return false
      switch (+type) {
        case 1: // 外部链接的时候
          window.open(banner.url, '_blank')
          break
        default:
          break
      }
    }
    initAdsList()
    const onItemDelClick = message => {
      if (!message.id) return false
      readBulletin(message.id)
    }
    const appClick = useAppClick()
    const goBulletin = () => {
      router.push('/user/notify')
    }
    initCompanyInfo()
    onMounted(() => {
      getIndexData()
    })
    return {
      state,
      companyState,
      appListLoading,
      logo,
      bulletinState,
      user,
      appList,
      companyCount,
      companyInfo,
      initAdsList,
      onBannerClick,
      timeFix,
      onItemDelClick,
      itemClick,
      appClick,
      goBulletin,
      hasPromise,
      hasAddressPromise,
      ICON_ADDRESS_PROMISS,
      addressListClick
    }
  }
})
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
