<template>
  <transition>
    <div class="home-guide" v-if="show">
      <div class="container">
        <div class="home-guide__avatar">
          <img :src="`${$accessBase}images/guide/home/avatar.png`" alt="" />
        </div>
        <div class="home-guide__menu">
          <img :src="`${$accessBase}images/guide/home/menu.png`" alt="" />
        </div>
        <div class="home-guide__arrow">
          <img :src="`${$accessBase}images/guide/home/arrow.png`" alt="" />
        </div>
        <div class="home-guide__tip">
          <img :src="`${$accessBase}images/guide/home/tip.png`" alt="" />
        </div>
        <div class="home-guide__know" @click="handleKnow">
          <img :src="`${$accessBase}images/guide/home/know.png`" alt="" />
        </div>
        <div class="home-guide__setting" @click="handleSetting">
          <img :src="`${$accessBase}images/guide/home/setting.png`" alt="" />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { updateGuideTime } from '@/apis'
import { useUser } from '@/hooks'
import { timeFormat } from '@/common/utils/format'
export default {
  name: 'homeGuide',
  setup() {
    const router = useRouter()
    const show = ref(false)
    const { user, setUser } = useUser()
    const currentDate = timeFormat(new Date(), 'YYYYMMDD')
    const remindTime = timeFormat(user.value.remindTime, 'YYYYMMDD')
    if (!user.value.remindTime && !user.value.mobile) {
      show.value = true
    } else if (remindTime !== currentDate && !user.value.mobile) {
      show.value = true
    }
    const updateLocalUserInfo = () => {
      user.value.remindTime = new Date()
      setUser(user.value)
    }
    const handleKnow = async () => {
      await updateGuideTime(user.value.uuid)
      show.value = false
      updateLocalUserInfo()
    }
    const handleSetting = async () => {
      await updateGuideTime(user.value.uuid)
      router.push({ name: 'UserInfo' })
      updateLocalUserInfo()
    }
    return { show, handleKnow, handleSetting }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
