<template>
  <div class="menu-item">
    <div class="menu-icon">
      <img :src="icon" alt="" />
    </div>
    <div class="menu-name">{{ name }}</div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'shortcut-item',
  props: {
    name: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
  setup() {
    return {}
  }
})
</script>
<style lang="scss" scoped>
.menu-item {
  width: 180px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
  color: $text-regular;
  cursor: pointer;
  transition: border-color 0.2s linear;
  .menu-icon {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    font-size: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .menu-name {
    flex: 1;
    margin-left: 12px;
    font-size: $font-size-base;
  }
  &:hover {
    border-color: $primary;
  }
}
</style>
